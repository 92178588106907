import React from 'react'
import { navigate } from '@reach/router'

import { asAdminSignedInOnlyPage } from '../../../services/AdminSession'
import Http from '../../../services/Http'

import Breadcrumbs from '../../../components/layout/Breadcrumbs'
import Page from '../../../components/layout/Page'
import VideoForm from '../../../components/videos/Form'

class AdminExperienceVideoNew extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      video: {}
    }
  }

  handlePost = (values, { setSubmitting }) => {
    const { experienceId } = this.props
    const video = { ...values, experienceId }

    return Http.post(`/admin/v1/videos`, { video })
    .then(() => {
      return this.props.onAccountChange()
    })
    .then(() => {
      navigate(`/admin/experiences/${experienceId}/videos`)
    })
  }

  render() {
    const { experience } = this.props

    const breadcrumbs = [
      { url: '/admin/experiences', label: 'Experiences' },
      { url: `/admin/experiences/${experience.id}/edit`, label: experience.name },
      { url: `/admin/experiences/${experience.id}/videos`, label: 'Videos' }
    ]

    return (
      <Page>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <h6 className="mb-0">New video</h6>
        <hr />

        <VideoForm onPatch={this.handlePost} />
      </Page>
    )
  }
}

export default asAdminSignedInOnlyPage(AdminExperienceVideoNew)

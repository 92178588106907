import _ from 'lodash'
import * as yup from 'yup'

export const experienceSchema = yup.object().shape({
  logoUrl: yup.string().url().required(),
  name: yup.string().required(),
  description: yup.string().required(),
  dripEnrollTag: yup.string().required(),
  dripUnenrollTag: yup.string().required(),
  dripCurrentWeekFieldName: yup.string().required()
})

export const experienceDefaults = {
  logoUrl: '',
  name: '',
  description: '',
  dripEnrollTag: '',
  dripUnenrollTag: '',
  dripCurrentWeekFieldName: ''
}

export const getInitialValues = (experience) => {
  return experience ? { ...experienceDefaults, ..._.pickBy(experience) } : experienceDefaults
}

import React from 'react'
import _ from 'lodash'
import axios from 'axios'

import { navigate } from '@reach/router'

import FormGroup from '../../components/forms/FormGroup'
import Layout from '../../components/layout/Layout'

import { asSignedOutOnlyPage, withAuth } from '../../contexts/Auth'
import { validateEditForm } from './helpers'

class Show extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      errors: {},
      password: '',
      alert: null
    }
  }

  handleChange = ({ target }) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({ [target.name]: value });
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({ isLoading: true, alert: null })

    const user = _.pick(this.state, 'password')
    const { errors, isValid } = validateEditForm(user)

    if (!isValid) {
      this.setState({ errors, isLoading: false })
      return;
    }

    axios.post(`/api/v1/passwords/${this.props.passwordToken}`, { user }, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(({ data: { token }}) => {
      this.props.auth.setToken(token)
      navigate('dashboard')
    })
    .catch(() => {
      this.setState({ alert: { type: 'danger', message: 'This token is invalid' }, isLoading: false })
    })
  }

  render() {
    const { errors, alert } = this.state
    return (
      <Layout>
        <div className="card m-auto" style={{ maxWidth: '400px' }}>
            <div className="card-body">
              <h6 className="mb-3 text-muted">Reset password</h6>
              { alert ? <div className={`alert alert-${alert.type}`}>{alert.message}</div> : null }
              <form className="d-flex flex-column" onSubmit={this.handleSubmit}>
                <FormGroup
                  placeholder="Password"
                  name="password"
                  type="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                  invalid={errors.password && !!errors.password.length}
                  errors={errors.password}
                />

                <button
                  className="btn btn-block btn-primary"
                  disabled={this.state.isLoading}
                  type="submit"
                >
                  Change password
                </button>
              </form>
            </div>
        </div>
      </Layout>
    )
  }
}

export default withAuth(asSignedOutOnlyPage(Show))

import _ from 'lodash'
import React from 'react'

import { Field } from 'formik'

import FormGroup from './FormGroup'

const FormikFormGroup = (props) => (
  <Field {...props} component={FormGroup} />
)

export default FormikFormGroup

import _ from 'lodash'
import React from 'react'
import { Formik, Form } from 'formik'
import { Row, Col, Card, CardBody, Button, FormGroup } from 'reactstrap'

import FormikFormGroup from '../../../admin/components/forms/FormikFormGroup'

import { DAYS_OPTIONS } from './helpers'

export default class DashboardReportForm extends React.Component {
  constructor(props) {
    super(props)
  }

  handleSubmit = (values, { setSubmitting }) => {
    this.props.onReportUpdate({ filters: values, setSubmitting })
  }

  render() {
    const { reportOptions } = this.props
    const initialValues = { reportId: reportOptions[0] && reportOptions[0].value, days: 30 }

    return (
      <Formik onSubmit={this.handleSubmit} initialValues={initialValues}>
        {({ isSubmitting }) => {
          return (
            <Form>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormikFormGroup label="Report" type="select" options={reportOptions} name="reportId" />
                    </Col>

                    <Col xs={12} md={3}>
                      <FormikFormGroup label="Time frame" type="select" options={DAYS_OPTIONS} name="days" />
                    </Col>

                    <Col xs={12} md={3} className="d-flex align-items-end">
                      <FormGroup>
                        <Button type="submit" disabled={isSubmitting} color="primary">Create a report</Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          )
        }}
      </Formik>
    )
  }
}

import React from 'react'
import { Row, Col, Button, Card, CardBody, FormGroup } from 'reactstrap'

import FormikFormGroup from '../../forms/FormikFormGroup'

const VideoLinkFields = ({ index, arrayHelpers }) => {
  return (
    <Card key={index} color="light" className="border-0 mb-2">
      <CardBody>
        <Row className="align-items-end">
          <Col xs={12} md={5}>
            <FormikFormGroup label="Label" name={`content.links[${index}].label`} />
          </Col>
          <Col xs={12} md={5}>
            <FormikFormGroup label="URL" name={`content.links[${index}].url`} />
          </Col>
          <Col xs={12} md={2} className="d-flex justify-content-center">
            <FormGroup>
              <Button className="border-0" outline color="secondary" type="button" onClick={() => arrayHelpers.remove(index)}>
                <h4 className="mb-0">&times;</h4>
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default VideoLinkFields

import React from 'react'

import { Router, Redirect } from '@reach/router'

import { asAdminSignedInOnlyPage } from '../../services/AdminSession'
import Http from '../../services/Http'

import AdminLayout from '../../components/layout/Layout'
import Page from '../../components/layout/Page'
import Loading from '../../components/layout/Loading'

import Edit from './Edit'
import VideoIndex from './videos/Index'
import VideoEdit from './videos/Edit'
import VideoNew from './videos/New'

class AdminExperienceShow extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      experience: {}
    }
  }

  componentDidMount() {
    this.getExperience()
    .then(() => {
      this.setState({ isLoading: false })
    })
  }

  getExperience = () => {
    const { experienceId } = this.props

    return Http.get(`/admin/v1/experiences/${experienceId}`)
    .then(({ experience }) => {
      this.setState({ experience })
    })
  }

  render() {
    const { isLoading, experience } = this.state

    return (
      <AdminLayout>
        <Loading isLoading={isLoading} wrapper={Page}>
          <Router>
            <Redirect noThrow from="/" to={`admin/experiences/${experience.id}/edit`} />
            <Edit experience={experience} path="/edit" />
            <VideoIndex experience={experience} path="/videos" />
            <VideoNew onAccountChange={this.getExperience} experience={experience} path="/videos/new" />
            <VideoEdit onAccountChange={this.getExperience} experience={experience} path="/videos/:videoId/edit" />
          </Router>
        </Loading>
      </AdminLayout>
    )
  }
}

export default asAdminSignedInOnlyPage(AdminExperienceShow)

import React from 'react'

import { Row, Col } from 'reactstrap'

import Navbar from './Navbar'

const Layout = ({ children }) => (
  <div className="container">
    <Row>
      <Col sm={3}>
        <Navbar />
      </Col>
      <Col sm={9}>
        {children}
      </Col>
    </Row>
  </div>
)

export default Layout

import axios from 'axios'

const Http = {}

const getHeaders = () => {
  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
  }
}

const handleSuccess = ({ data }) => data
const handleError = ({ response }) => Promise.reject(response.data || {})

Http.get = (url) => (
  axios.get(url, { headers: getHeaders() })
  .then(handleSuccess)
  .catch(handleError)
)

Http.post = (url, data) => (
  axios.post(url, data, { headers: getHeaders() })
  .then(handleSuccess)
  .catch(handleError)
)

Http.patch = (url, data) => (
  axios.patch(url, data, { headers: getHeaders() })
  .then(handleSuccess)
  .catch(handleError)
)

export default Http

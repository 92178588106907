import React from 'react'
import { Row, Col } from 'reactstrap'

const FormSection = ({ title, children }) => {
  return (
    <Row>
      <Col xs={12} md={4}>{title}</Col>
      <Col xs={12} md={8}>{children}</Col>
    </Row>
  )
}

export default FormSection

import React from 'react'
import Item from './Item'

import { withAuth } from '../../contexts/Auth'

class Index extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { videos, weekToShowId, currentWeekId } = this.props
    const focusedWeekId = weekToShowId || currentWeekId

    return (
        <div className="pt-3 mb-5 row justify-content-end video-list">
          <div className="col-12 col-md-12">
            {
              videos.length
              ? <div className="row">
                  {
                    videos.map(video => (
                      <div key={video.id} className="col-12">
                        <Item
                          isCurrent={video.weekId === currentWeekId}
                          isFocused={video.weekId === focusedWeekId }
                          {...video}
                        />
                      </div>
                    ))
                  }
                </div>
              : <div className="alert alert-warning">Nothing to show here</div>
            }
          </div>
        </div>
    )
  }
}

export default withAuth(Index)

import React from 'react'

import { Link } from '@reach/router'

import * as http from '../helpers/axios'

import Layout from '../components/layout/Layout'

import { withAuth } from '../contexts/Auth'
import { asSignedOutOnlyPage } from '../contexts/Auth'

class SignIn extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      email: '',
      password: ''
    }
  }

  handleChange = ({ target }) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({ [target.name]: value });
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({ isLoading: true })
    const { email, password } = this.state

    return http.request('post', '/api/v1/sessions', { username: email, password })
    .then(({ token }) => { this.props.auth.setToken(token) })
    .catch(() => {
      this.setState({
        isLoading: false,
        alert: { type: 'danger', text: "Your email and password don't match" }
      })
    })
  }

  render() {
    const { alert, isLoading } = this.state
    return (
      <Layout>
        <div className="card mx-auto mb-5" style={{ maxWidth: '400px' }}>
          <div className="card-body">
            <h6 className="mb-3 text-muted">Sign in</h6>

            { alert && !isLoading ? <div className={`alert alert-${alert.type}`}>{alert.text}</div> : null }

            <form className="d-flex flex-column" onSubmit={this.handleSubmit}>
              <input
                name="email"
                value={this.state.invitationCode}
                type="text"
                placeholder="Email"
                onChange={this.handleChange}
                className="form-control mb-2"
              />

              <input
                name="password"
                value={this.state.invitationCode}
                type="password"
                placeholder="Password"
                onChange={this.handleChange}
                className="form-control mb-2"
              />
              <button className="btn btn-primary" type="submit" disabled={isLoading}>Get started!</button>
            </form>
            <div className="d-flex justify-content-end">
              <Link to="/passwords/new">Forgot your password?</Link>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default asSignedOutOnlyPage(withAuth(SignIn))

import React from 'react'

import { Link } from '@reach/router'
import { Alert, Card, CardBody, Button } from 'reactstrap'

import { asAdminSignedInOnlyPage } from '../../../services/AdminSession'
import Http from '../../../services/Http'

import AdminLayout from '../../../components/layout/Layout'
import Page from '../../../components/layout/Page'
import Form from '../../../components/invitations/Form'
import Loading from '../../../../components/layout/Loading'
import Item from '../../../components/invitations/Item'

const ROLE_OPTIONS = [{ value: 'user', label: 'User' }, { value: 'manager', label: 'Manager' }]

class AdminAccountInvitationIndex extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      invitations: [],
      account: {},
      alert: null
    }
  }

  componentDidMount() {
    const { accountId } = this.props

    Promise.all([
      this.getAccount(accountId),
      this.getInvitations(accountId)
    ])
    .then(() => {
      this.setState({ isLoading: false })
    })
  }

  getAccount = (accountId) => (
    Http.get(`/admin/v1/accounts/${accountId}`)
    .then(({ account }) => {
      this.setState({ account })
    })
  )

  getInvitations = (accountId) => (
    Http.get(`/admin/v1/accounts/${accountId}/invitations`)
    .then(({ invitations }) => {
      this.setState({ invitations })
    })
  )

  handlePost = (values, { setSubmitting, resetForm }) => {
    this.setState({ alert: null })

    const { accountId } = this.props
    const invitation = values

    return Http.post(`/admin/v1/accounts/${accountId}/invitations`, { invitation })
    .then(() => {
      resetForm()
      setSubmitting(false)
      this.setState({ alert: { text: 'Invitation successfully created' } })
      this.getInvitations(accountId)
    })
  }

  handleSync = () => {
    this.setState({ alert: null })
    const { accountId } = this.props

    return Http.post(`/admin/v1/drip/sync_invitations?accountId=${accountId}`, {})
    .then(() => {
      this.setState({ alert: { text: "Invitations sync'ed to Drip" } })
      this.getInvitations(accountId)
    })
  }

  render() {
    const { isLoading, account, invitations, alert } = this.state

    return (
      <AdminLayout>
        <Page>
          <Loading isLoading={isLoading}>
            <div className="mb-3">
              <Link to="/admin/accounts">Accounts</Link>
            </div>

            <h6>{account.name} invitations</h6>
            <hr />

            {alert
              ? <div>
                  <Alert color="success">{alert.text}</Alert>
                  <hr />
                </div> : null}

            <Form
              roleOptions={ROLE_OPTIONS}
              departments={account.departments || []}
              onPost={this.handlePost}
            />

            <hr />

            <Button outline type="button" color="primary" onClick={this.handleSync} > Sync invitations to Drip </Button>

            {!invitations.length
              ? <Alert color="warning">There aren't any outstanding invitations in this account</Alert>
              : null}

            {invitations.map(invitation => (
              <div key={invitation.id} className="mb-3"><Item {...invitation} /></div>))}
          </Loading>
        </Page>
      </AdminLayout>
    )
  }
}

export default asAdminSignedInOnlyPage(AdminAccountInvitationIndex)

import React from 'react'
import JWT from 'jsonwebtoken'

import * as http from '../helpers/axios'

import { Redirect } from '@reach/router'

const AuthContext = React.createContext(null)

const getTokenPayload = token => (token ? JWT.decode(token) : null)

export const provideAuth = Component => {
  class AuthProvider extends React.Component {
    constructor(props) {
      super(props)

      const tokenPayload = getTokenPayload(localStorage.getItem('token'))

      this.state = {
        tokenPayload,
        isSignedIn: tokenPayload && !!tokenPayload.id,
        currentUser: null,
        currentAccount: null
      }
    }

    componentDidMount() {
      window.addEventListener('storage', this.handleTokenChange)
      this.updateCurrentUserData()
    }

    componentWillUnmount() {
      window.removeEventListener('storage', this.handleTokenChange)
    }

    handleTokenChange = ({ key, storageArea }) => {
      if (key === 'token' && storageArea === localStorage) {
        this.updateCurrentUserData()
      }
    }

    updateCurrentUserData = () => {
      const tokenPayload = getTokenPayload(localStorage.getItem('token'))

      if (!tokenPayload) {
        this.setState({
          tokenPayload,
          currentUser: null,
          currentAccount: null,
          isSignedIn: false
        })

        return
      } else {
        this.setState({
          tokenPayload,
          isSignedIn: true
        })
      }

      http.request('get', `/api/v1/users/${tokenPayload.id}`)
      .then(({ user }) => { this.setState({ currentUser: user }) })

      http.request('get', `/api/v1/accounts/${tokenPayload.accountId}`)
      .then(({ account }) => { this.setState({ currentAccount: account }) })
    }

    setToken = (token) => {
      if (token) {
        localStorage.setItem('token', token)
      } else {
        localStorage.removeItem('token')
      }

      this.updateCurrentUserData()
    }

    render() {
      const value = {
        isSignedIn: this.state.isSignedIn,
        tokenPayload: this.state.tokenPayload,
        currentUser: this.state.currentUser,
        currentAccount: this.state.currentAccount,
        setToken: this.setToken
      }

      return (
        <AuthContext.Provider value={value}>
          <Component {...this.props} />
        </AuthContext.Provider>
      )
    }
  }

  return AuthProvider
}

export const withAuth = Component => (
  props => (
    <AuthContext.Consumer>
      {auth => (
        <Component {...props} auth={auth} />
      )}
    </AuthContext.Consumer>
  )
)

export const asSignedInOnlyPage = (Component) => {
  const SignedInOnlyPage = (props) => (
    props.auth && props.auth.isSignedIn ? <Component {...props} /> : <Redirect noThrow to="/signin" />
  )

  return withAuth(SignedInOnlyPage)
}

export const asManagerOnlyPage = (Component) => {
  const ManagerOnlyPage = (props) => (
    (props.auth && props.auth.isSignedIn && props.auth.tokenPayload.role == 'manager')
    ? <Component {...props} /> : <Redirect noThrow to="/" />
  )

  return withAuth(ManagerOnlyPage)
}

export const asSignedOutOnlyPage = (Component) => {
  const SignedOutOnlyPage = (props) => (
    props.auth && props.auth.isSignedIn ? <Redirect noThrow to="/" /> : <Component {...props} />
  )

  return withAuth(SignedOutOnlyPage)
}

import _ from 'lodash'
import React from 'react'
import { Formik, Form, Field } from 'formik'
import { Row, Col, Button } from 'reactstrap'

import FormikFormGroup from '../forms/FormikFormGroup'
import FormSection from '../forms/FormSection'

import { convertOptions } from '../forms/helpers'
import { invitationSchema, invitationDefaults } from './helpers'

const AdminInvitationForm = ({ roleOptions, departments, onPost }) => {
  const departmentIdOptions = [
    { value: '', label: 'Select a department'},
    ...convertOptions(departments, { valueKey: 'id', labelKey: 'name' })
  ]

  const handleDepartmentIdChange = (value, { setFieldValue }) => {
    const departmentName = _.get(_.find(departments, { id: value }), 'name', '')
    setFieldValue('department.id', value)
    setFieldValue('department.name', departmentName)
  }

  return (
    <Formik
      onSubmit={onPost} initialValues={invitationDefaults} validationSchema={invitationSchema} enableReinitialize={false}>
      {({ isSubmitting, resetForm, setFieldValue, setFieldTouched }) => (
        <Form className="mb-0">
          <FormSection title="Invite a new user">
            <Row>
              <Col xs={12} md={10}>
                <FormikFormGroup label="Email" name="email" />
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6}>
                <FormikFormGroup
                  label="Department" name="department.id" type="select"
                  onChange={e => { handleDepartmentIdChange(e.target.value, { setFieldValue })}}
                  onBlur={() => { setFieldTouched('department.id') }}
                  options={departmentIdOptions} />
              </Col>

              <Col xs={12} md={6}>
                <FormikFormGroup
                  label="Role" name="role" type="select"
                  options={roleOptions} />
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={8}>
                <FormikFormGroup label="First name" name="firstName" />
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={8}>
                <FormikFormGroup label="Last name" name="lastName" />
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={8}>
                <FormikFormGroup label="Phone" name="phone" type="phone" />
              </Col>
            </Row>
          </FormSection>

          <hr />

          <div className="d-flex justify-content-end">
            <Button type="button" disabled={isSubmitting} onClick={() => { resetForm() }} className="mr-2" outline color="secondary">Cancel</Button>
            <Button type="submit" disabled={isSubmitting} color="primary">Invite user</Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AdminInvitationForm

import React from 'react'
import { Formik, Form } from 'formik'
import { Row, Col, Button } from 'reactstrap'

import FormikFormGroup from '../forms/FormikFormGroup'
import FormSection from '../forms/FormSection'

import { experienceSchema, getInitialValues } from './helpers'

const AdminExperienceForm = ({ experience, onPatch }) => {
  const initialValues = getInitialValues(experience)

  return (
    <Formik onSubmit={onPatch} initialValues={initialValues} validationSchema={experienceSchema}>
      {({ isSubmitting, resetForm }) => {
        const handleCancel = () => { resetForm(initialValues) }

        return (
          <Form className="mb-0">
            <FormSection title="General information">
              <Row>
                <Col xs={12} sm={8}>
                  <FormikFormGroup label="Logo URL" name="logoUrl" />
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={8}>
                  <FormikFormGroup label="Name" name="name" />
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={10}>
                  <FormikFormGroup type="textarea" rows={4} label="Description" name="description" />
                </Col>
              </Row>
            </FormSection>

            <hr />

            <FormSection title="Drip parameters">
              <Row>
                <Col xs={12} md={8}>
                  <FormikFormGroup label="Drip enroll tag" name="dripEnrollTag" />
                  <FormikFormGroup label="Drip unenroll tag" name="dripUnenrollTag" />
                  <FormikFormGroup label="Drip current week field" name="dripCurrentWeekFieldName" />
                </Col>
              </Row>
            </FormSection>

            <hr />

            <div className="d-flex justify-content-end">
              <Button type="button" disabled={isSubmitting} onClick={handleCancel} className="mr-2" outline color="secondary">Cancel</Button>
              <Button type="submit" disabled={isSubmitting} color="primary">Update experience</Button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AdminExperienceForm

import moment from 'moment'
import React from 'react'

const colors = {
  accepted: 'success',
  sent: 'success',
  expired: 'danger',
  pending: 'warning',
}

const AdminInvitationItem = ({ department, firstName, lastName, email, status, expiresAt, sentAt, url }) => (
  <div>
    <hr />
    <div className="d-flex row">
      <div className="col-9">
        <div className="text-muted">{email}</div>
      </div>

      <div className="col-3 d-flex align-items-start justify-content-end">
        <span style={{ width: '80px' }} className={`badge badge-${colors[status]}`}>{status}</span>
      </div>

      <div className="col-12">
        <div>{firstName} {lastName} {department && `(${department.name})`}</div>
      </div>

      <div className="col-12">
        <a href={url}>Invitation link</a>
      </div>

      {sentAt
        ? <div className="col-12">
            <div className="text-muted font-weight-light">
              Sent {moment(sentAt).format('MMMM Do YYYY')}
            </div>
          </div> : null}

      <div className="col-12">
        <div className="text-muted font-weight-light">
          {status === 'expired' ? 'Expired' : 'Expires' } {moment(expiresAt).format('MMMM Do YYYY')}
        </div>
      </div>
    </div>
  </div>
)

export default AdminInvitationItem

import React from 'react'

import { Alert } from 'reactstrap'

import { asAdminSignedInOnlyPage } from '../../services/AdminSession'
import Http from '../../services/Http'

import AdminLayout from '../../components/layout/Layout'
import Page from '../../components/layout/Page'
import Loading from '../../components/layout/Loading'
import Item from '../../components/accounts/Item'

class AdminAccountIndex extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      accounts: []
    }
  }

  componentDidMount() {
    Http.get('/admin/v1/accounts')
    .then(({ accounts }) => {
      this.setState({ accounts, isLoading: false })
    })
  }

  render() {
    const { isLoading, accounts } = this.state

    return (
      <AdminLayout>
        <Page>
          <Loading isLoading={isLoading}>
            <h6>Accounts</h6>

            {!accounts.length
              ? <Alert color="warning">There aren't any accounts</Alert>
              : null}

            {accounts.map(account => <Item key={account.id} account={account} />)}
          </Loading>
        </Page>
      </AdminLayout>
    )
  }
}

export default asAdminSignedInOnlyPage(AdminAccountIndex)

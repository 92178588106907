import React from 'react'

import { Alert } from 'reactstrap'

import { asAdminSignedInOnlyPage } from '../../../services/AdminSession'
import Http from '../../../services/Http'

import Breadcrumbs from '../../../components/layout/Breadcrumbs'
import Page from '../../../components/layout/Page'
import Loading from '../../../components/layout/Loading'
import VideoForm from '../../../components/videos/Form'

class AdminExperienceVideoEdit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      video: {},
      alert: null
    }
  }

  componentDidMount() {
    this.getVideo()
    .then(() => {
      this.setState({ isLoading: false })
    })
  }

  getVideo = () => {
    const { videoId } = this.props

    return Http.get(`/admin/v1/videos/${videoId}`)
    .then(({ video }) => {
      this.props.onAccountChange()
      this.setState({ video })
    })
  }

  handlePatch = (values, { setSubmitting }) => {
    this.setState({ alert: null })
    const { videoId } = this.props
    const video = values

    return Http.patch(`/admin/v1/videos/${videoId}`, { video })
    .then(({ video }) => {
      this.setState({
        video, alert: { text: 'Video successfully updated' }
      })
      return this.getVideo()
    })
    .then(() => {
      setSubmitting(false)
    })
  }

  render() {
    const { isLoading, video, alert } = this.state
    const { experience } = this.props

    const breadcrumbs = [
      { url: '/admin/experiences', label: 'Experiences' },
      { url: `/admin/experiences/${experience.id}/edit`, label: experience.name },
      { url: `/admin/experiences/${experience.id}/videos`, label: 'Videos' }
    ]

    return (
      <Page>
        <Loading isLoading={isLoading}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />

          <h6 className="mb-0">{video.title}</h6>
          <hr />

          {alert
            ? <div>
                <Alert color="success">{alert.text}</Alert>
                <hr />
              </div> : null}

          <VideoForm video={video} onPatch={this.handlePatch} />
        </Loading>
      </Page>
    )
  }
}

export default asAdminSignedInOnlyPage(AdminExperienceVideoEdit)

import React from 'react'
import { Router, Redirect } from '@reach/router'

import SessionNew from './pages/sessions/New'

import AccountIndex from './pages/accounts/Index'
import AccountInvitationIndex from './pages/accounts/invitations/Index'

import ExperienceIndex from './pages/experiences/Index'
import ExperienceShow from './pages/experiences/Show'

const AdminRoutes = () => (
  <Router>
    <Redirect noThrow from="/" to="/admin/accounts" />

    <SessionNew path="sessions/new" />

    <AccountIndex path="accounts" />
    <AccountInvitationIndex path="accounts/:accountId/invitations" />

    <ExperienceIndex path="experiences" />
    <ExperienceShow path="experiences/:experienceId/*" />
  </Router>
)

export default AdminRoutes

import _ from 'lodash'
import React from 'react'
import * as RS from 'reactstrap'

import * as http from '../../../helpers/axios'

import Layout from '../../components/layout/Layout'

import { asAdminSignedOutOnlyPage } from '../../services/AdminSession'


class AdminSessionNew extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      session: { token: '' },
      alert: null,
      isLoading: false
    }
  }

  handleChange = ({ target }) => {
    this.setState({
      session: {
        ...this.state.session,
        [target.name]: target.value
      }
    });
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.setState({ alert: null, isLoading: true })

    const { token } = this.state.session

    return http.request('post', '/admin/v1/sessions', { token })
    .then(({ token }) => {
      this.props.adminSession.setToken(token)
    })
    .catch((error) =>  {
      if (error.errors && _.includes(error.errors, 'Unauthorized')) {
        this.setState({
          isLoading: false,
          alert: { text: 'Invalid admin credentials', type: 'danger' }
        })

        return;
      }

      return Promise.reject(error)
    })
  }

  render() {
    const { isLoading, session, alert } = this.state
    return (
      <Layout>
        <div className="card ml-4 my-4" style={{ maxWidth: '400px' }}>
          <div className="card-body">
            <h6 className="mb-3 text-muted">Sign in</h6>

            {alert ? <RS.Alert color={alert.type}>{alert.text}</RS.Alert> : null}

            <form className="d-flex flex-column" onSubmit={this.handleSubmit}>
              <input
                name="token"
                value={session.token}
                type="text"
                placeholder="Admin token"
                onChange={this.handleChange}
                className="form-control mb-2"
              />

              <button className="btn btn-primary" type="submit" disabled={isLoading}>Get started!</button>
            </form>
          </div>
        </div>
      </Layout>
    )
  }
}

export default asAdminSignedOutOnlyPage(AdminSessionNew)

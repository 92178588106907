import React from 'react'

import * as axios from '../helpers/axios'

import Layout from '../components/layout/Layout'
import Loading from '../components/layout/Loading'
import UserForm from '../components/users/Form'

import { asSignedInOnlyPage } from '../contexts/Auth'

class Settings extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      departments: [],
      calendarServices: {},
      user: {}
    }
  }

  componentDidMount() {
    this.getProfile()
  }

  getProfile = () => {
    const { tokenPayload } = this.props.auth

    Promise.all([
      axios.request('get', `/api/v1/users/${tokenPayload.id}`)
      .then(({ user }) => { this.setState({ user }) }),
      axios.request('get', `/api/v1/accounts/${tokenPayload.accountId}/departments`)
      .then(({ departments }) => { this.setState({ departments }) }),
      axios.request('get', '/add_event/v1/services')
      .then(({ services }) => { this.setState({ calendarServices: services }) })
    ])
    .then(() => {
      this.setState({ isLoading: false })
    })
  }

  render() {
    const { isLoading, user, departments, calendarServices } = this.state

    return (
      <Layout>
        <Loading isLoading={isLoading}>
          <h5>Profile Settings</h5>
          <hr />
          <div className="row justify-content-end">
            <div className="col-12 col-md-8">
              <UserForm user={user} options={{ departments, calendarServices }} />
            </div>
          </div>
        </Loading>
      </Layout>
    )
  }
}

export default asSignedInOnlyPage(Settings)

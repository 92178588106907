import _ from 'lodash'
import * as axios from '../../helpers/axios'
import React from 'react'
import Player from '@vimeo/player'

import { withAuth } from '../../contexts/Auth'

const INTERVAL_DISABLED = process.env.USER_VIDEO_INTERVAL_DISABLE !== 'false'
const THROTTLE_INTERVAL =
  process.env.USER_VIDEO_INTERVAL_SECS
  ? process.env.USER_VIDEO_INTERVAL_SECS * 1000
  : 15000

class CustomPlayer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      userVideo: { ...this.props.userVideo },
      isNewPlay: true,
      percent: 0
    }

    this.playerRef = React.createRef()

    this.throttledUpdateUserVideo = _.throttle(this.updateUserVideo, THROTTLE_INTERVAL)
  }

  componentDidMount() {
    const { vimeoId, videoUrl } = this.props
    const playerOptions = { responsive: true }
    if (vimeoId) { playerOptions.id = vimeoId }
    if (videoUrl) { playerOptions.url = videoUrl }

    this.player = new Player(this.playerRef.current, playerOptions)

    if (this.props.userVideo.lastPlayed > 1) {
      this.player.setCurrentTime(this.props.userVideo.lastPlayed - 1)
    }

    this.player.on('play', this.setStarted)
    this.player.on('ended', this.setEnded)
    this.player.on('timeupdate', this.setTimeData)
  }

  componentWillUnmount() {
    this.player.off('play')
    this.player.off('ended')
    this.player.off('timeupdate')
  }

  updateUserVideo = () => {
    const userId = this.props.auth.tokenPayload.id
    const videoId = this.props.id

    axios.request('post', '/api/v1/user_videos', {
      userVideo: { ...this.state.userVideo, videoId, userId },
      ..._.pick(this.state, ['isNewPlay', 'percent'])
    })
    .then(({ userVideo }) => {
      const status = userVideo.ended
      ? 'ended'
      : userVideo.started ? 'started' : 'notSeen'

      this.setState({ isNewPlay: false })
      this.props.onStatusChange(status)
    })
  }

  setTimeData = ({ seconds, percent }) => {
    const furthestPlayed = this.props.userVideo.furthestPlayed < seconds
    ? seconds
    : this.props.userVideo.furthestPlayed

    const lastPlayed = seconds

    this.setState({
      percent,
      userVideo: { ...this.state.userVideo, lastPlayed, furthestPlayed }
    })

    if (!INTERVAL_DISABLED) {
      this.throttledUpdateUserVideo()
    }
  }

  setStarted = () => {
    this.setState({ userVideo: { ...this.state.userVideo, started: true } })
    this.updateUserVideo()
  }

  setEnded = () => {
    this.setState({ userVideo: { ...this.state.userVideo, ended: true } })
    this.updateUserVideo()
  }

  render() {
    return <div className="card-img-top bg-dark" ref={this.playerRef}></div>
  }
}

export default withAuth(CustomPlayer)

import React from 'react'

import { Link } from '@reach/router'
import {
  Navbar,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem
} from 'reactstrap'

import logoUrl from '../../assets/images/orsini-logo.png'

import { withAuth } from '../../contexts/Auth'

import SignOut from '../authentication/SignOut'

class NavBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const { auth } = this.props

    return (
      <div>
        <Navbar light expand="md" className="px-0 mb-4">
          <div className="d-flex align-items-center">
            <a href='https://theorsiniway.com'><img className="navbar-logo pr-3" src={logoUrl} /></a>
            { auth.currentAccount
              ? auth.currentAccount.logoUrl
                ? <img className="pl-3 border-left navbar-logo" src={auth.currentAccount.logoUrl} />
                : <div className="pl-3 border-left h5 mb-0">{auth.currentAccount.name}</div>
              : null }
          </div>

          <NavbarToggler onClick={this.toggle} />

          <Collapse isOpen={this.state.isOpen} navbar>
            {
              auth.isSignedIn
              ? <Nav className="ml-auto" navbar>
                  {auth.currentUser && auth.currentUser.role === 'manager'
                    ? <NavItem><Link className="nav-link" to="/dashboard">Dashboard</Link></NavItem>
                    : null}
                  <NavItem><Link className="nav-link" to="/experiences">Program</Link></NavItem>
                  <NavItem><a className="nav-link" href="mailto:info@theorisinway.com">Contact Us</a></NavItem>
                  <NavItem><Link className="nav-link" to="/settings">Profile</Link></NavItem>
                  <NavItem><SignOut className="nav-link" /></NavItem>
                </Nav>
              : <Nav className="ml-auto" navbar>
                  <NavItem><Link className="nav-link" to="/signin">Sign in</Link></NavItem>
                </Nav>
            }
          </Collapse>
        </Navbar>
      </div>
    )
  }
}

export default withAuth(NavBar)

import React from 'react'

const Loading = ({ isLoading, children, wrapper }) => {
  const Wrapper = wrapper || (() => (<div>{children}</div>))
  return (
    isLoading
    ? <Wrapper>Loading...</Wrapper>
    : children
  )
}

export default Loading

import React from 'react'
import _ from 'lodash'
import * as axios from '../../helpers/axios'

import { navigate } from '@reach/router'

import { Input, FormText } from 'reactstrap'

import FormGroup from '../../components/forms/FormGroup'
import PhoneInput from '../../components/forms/PhoneInput'

import Layout from '../../components/layout/Layout'
import Loading from '../../components/layout/Loading'

import { withAuth } from '../../contexts/Auth'
import { validateForm } from './helpers'

class Show extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      calendarServices: [],
      invitation: { email: '', isValid: true, user: { department: {} } },
      errors: {},
      firstName: '',
      lastName: '',
      password: '',
      phone: ''
    }
  }

  componentDidMount() {
    Promise.all([
      axios.request('get', `/api/v1/invitations/${this.props.invitationCode}`)
      .then(({ invitation }) => {
        const { firstName, lastName, phone } = invitation.user
        this.setState({ invitation, firstName, lastName, phone })
      }),
      axios.request('get', '/add_event/v1/services')
      .then(({ services }) => { this.setState({ calendarServices: services }) })
    ])
    .then(() => { this.setState({ isLoading: false }) })
  }

  handleChange = ({ target }) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({ [target.name]: value });
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({ isLoading: true })

    const code = this.props.invitationCode
    const user = _.pick(this.state, ['firstName', 'lastName', 'password', 'phone', 'calendarService'])

    const { errors, isValid } = validateForm(user)

    if (!isValid) {
      this.setState({ errors, isLoading: false })
      return;
    }

    axios.request('post', `/api/v1/invitations/${code}`, { user })
    .then(({ token }) => {
      this.props.auth.setToken(token)
      return axios.request('get', `/api/v1/intro_video`)
    })
    .then(({ video }) => {
      navigate(`/videos/${video.slug}`)
    })
    .catch(() => {
      this.setState({ isLoading: false })
    })
  }

  render() {
    this.props.auth.isSignedIn && navigate('/')

    const { errors, calendarServices, invitation } = this.state
    const { email } = this.state.invitation

    return (
      <Layout>
        <Loading isLoading={this.state.isLoading}>
          <h5 className="mb-3">Accept invitation</h5>
          <hr />
          <div className="row justify-content-end">
            <div className="col-12 col-md-8">
              <div className="mb-3">
                {
                  this.state.invitation.isValid
                  ? <div className="alert alert-info">
                      <div className="row">
                        <div className="col-12 col-md-8 mb-md-2">
                          <div className="text-muted col-12">Email</div>
                          <div className="col-12 mb-3 mb-md-0">{email}</div>
                        </div>
                        <div className="col-12 col-md-8">
                          <div className="text-muted col-12">Department</div>
                          <div className="col-12 mb-3 mb-md-0">{invitation.user.department.name}</div>
                        </div>
                      </div>
                    </div>
                  : <div className="alert alert-danger">This invitation is invalid</div>
                }
              </div>

              <form className="d-flex flex-column" onSubmit={this.handleSubmit}>
                <FormGroup
                  placeholder="First Name"
                  name="firstName"
                  value={this.state.firstName}
                  onChange={this.handleChange}
                  invalid={errors.firstName && !!errors.firstName.length}
                  errors={errors.firstName}
                />

                <FormGroup
                  placeholder="Last Name"
                  name="lastName"
                  value={this.state.lastName}
                  onChange={this.handleChange}
                  invalid={errors.lastName && !!errors.lastName.length}
                  errors={errors.lastName}
                />

                <input type="email" readOnly className="not-visible" name="username" value={email} />
                <FormGroup
                  placeholder="Password"
                  name="password"
                  type="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                  invalid={errors.password && !!errors.password.length}
                  errors={errors.password}
                />

                <FormGroup name="phone" errors={errors.phone}>
                  <PhoneInput
                    name="phone"
                    value={this.state.phone || ''}
                    invalid={errors.phone && !!errors.phone.length}
                    onChange={this.handleChange}
                  />
                  <FormText>Optional: Add if you prefer SMS messages. Messages are sent weekday mornings between 8 and 11 AM.</FormText>
                </FormGroup>

                <FormGroup name="calendarService" errors={errors.phone}>
                  <Input
                    type="select"
                    name="calendarService"
                    value={this.state.calendarService || ''}
                    invalid={errors.calendarService && errors.calendarService.length}
                    onChange={this.handleChange}
                    className="text-muted"
                  >
                    <option value="">Pick the calendar service you use</option>
                    { calendarServices.map(({ id, name }) => <option key={id} value={id}>{name}</option>) }
                  </Input>
                  <FormText>Optional: This will make it easier for you to re-schedule activities</FormText>
                </FormGroup>

                <button
                  className="btn btn-block btn-primary"
                  disabled={this.state.isLoading || !this.state.invitation.isValid}
                  type="submit"
                >
                  Get started!
                </button>
              </form>
            </div>
          </div>
        </Loading>
      </Layout>
    )
  }
}

export default withAuth(Show)

import _ from 'lodash'

export const convertOptions = (options, { valueKey, labelKey }) => {
  const mapping = {
    [labelKey]: 'label',
    [valueKey]: 'value'
  }

  return _.map(options, option => _.mapKeys(option, (_value, key) => mapping[key] || key))
}

import React from 'react'

import { Link } from '@reach/router'

import Page from '../../../components/layout/Page'
import Breadcrumbs from '../../../components/layout/Breadcrumbs'

import { asAdminSignedInOnlyPage } from '../../../services/AdminSession'

class AdminExperienceVideoIndex extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { experience } = this.props

    const breadcrumbs = [
      { url: '/admin/experiences', label: 'Experiences' },
      { url: `/admin/experiences/${experience.id}/edit`, label: experience.name }
    ]

    return (
      <Page>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="mb-0">Videos</h6>
          <Link className="btn btn-outline-primary" to={`new`}>Add video</Link>
        </div>

        {experience.videos
          ? experience.videos.map(video => (
            <div key={video.id}>
              <hr />
              <div className="d-flex justify-content-between align-items-center">
                <div><span className="text-muted">{video.weekId}</span> {video.title}</div>

                <Link className="btn btn-outline-secondary" to={`/admin/experiences/${experience.id}/videos/${video.id}/edit`}>
                  Edit
                </Link>
              </div>
            </div>
          )) : null}
      </Page>
    )
  }
}

export default asAdminSignedInOnlyPage(AdminExperienceVideoIndex)

import React from 'react'
import axios from 'axios'

import { Link } from '@reach/router'

import { asSignedInOnlyPage } from '../../contexts/Auth'

import Layout from '../../components/layout/Layout'
import Loading from '../../components/layout/Loading'
import Item from '../../components/videos/Item'

class Show extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      video: { userVideo: {} },
      userExperience: {}
    }
  }

  componentDidMount() {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }

    axios.get(`/api/v1/videos/${this.props.videoSlug}`, { headers })
    .then(({ data: { video, userExperience }}) => {
      this.setState({
        video: { ...video, userVideo: video.userVideos[0] || {} },
        userExperience,
        isLoading: false
      })
    })
  }

  render() {
    const { video, userExperience, isLoading } = this.state

    return (
      <Layout>
        <Loading isLoading={isLoading}>
          <Item
            hideCollapseButton={true}
            isCurrent={video.weekId == userExperience.currentWeekId}
            isFocused={true}
            {...video}
          />

          <div className="my-3">
            <Link to={`/experiences/${video.experienceId}`}>
              View all videos
            </Link>
          </div>
        </Loading>
      </Layout>
    )
  }
}

export default asSignedInOnlyPage(Show)

import validator from 'validator'

export const validateForm = (data) => {
  const validations = {
    firstName: firstName => {
      const errors = []
      if (validator.isEmpty(firstName)) { errors.push('First name is required') }
      return errors
    },
    lastName: lastName => {
      const errors = []
      if (validator.isEmpty(lastName)) { errors.push('Last name is required') }
      return errors
    },
    password: password => {
      const errors = []
      if (!validator.isLength(password, { min: 8 })) { errors.push('Password must be at least 8 characters long') }
      return errors
    },
    phone: phone => {
      const errors = []
      if (phone && !validator.isLength(phone, 10)) { errors.push('Invalid phone number') }
      return errors
    }
  }

  const errors = {}
  Object.keys(validations).forEach(key => {
    const messages = validations[key](data[key])
    if (messages.length) { errors[key] = messages }
  })

  const isValid = !Object.keys(errors).length

  return { isValid, errors }
}

import React from 'react'

import { Link } from '@reach/router'

const AdminAccountItem = ({ account }) => {
  return (
    <div>
      <hr />
      <div className="d-flex align-items-center justify-content-between">
        <div><span className="text-muted">{account.id}</span> {account.name}</div>
        <Link className="btn btn-outline-secondary border-0" to={`/admin/accounts/${account.id}/invitations`}>Invitations</Link>
      </div>
    </div>
  )
}

export default AdminAccountItem

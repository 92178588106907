import React from 'react'

import { Input } from 'reactstrap'

import { formatPhone } from './helpers/phoneInput'

class PhoneInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: this.props.value ? formatPhone(this.props.value) : '',
      focused: false
    }
  }

  componentWillReceiveProps({ value }) {
    this.setState({ value: this.state.focused ? value : formatPhone(value) })
  }

  handleFocus = () => {
    this.setState({ value: this.props.value, focused: true })
  }

  handleBlur = () => {
    this.setState({ value: formatPhone(this.props.value), focused: false })
  }

  render() {
    return (
      <Input
        name={this.props.name}
        type="tel"
        placeholder="enter 10 digit number without spaces"
        value={this.state.value}
        onChange={this.props.onChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        invalid={this.props.invalid}
      />
    )
  }
}

export default PhoneInput

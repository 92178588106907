import React from 'react'
import axios from 'axios'

import Layout from '../../components/layout/Layout'
import Loading from '../../components/layout/Loading'

import VideosIndex from '../../components/videos/Index'

import { asSignedInOnlyPage } from '../../contexts/Auth'

class Show extends React.Component {
  constructor(props) {
    super(props)

    const weekToShowId = parseInt(this.props.location.hash.replace('#', ''))

    this.state = {
      isLoading: true,
      experience: { userExperience: {} },
      weekToShowId
    }
  }

  componentDidMount() {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }

    axios.get(`/api/v1/experiences/${this.props.experienceId}`, { headers })
    .then(({ data: { experience }}) => {
      this.setState({ experience, isLoading: false })
    })
  }

  render() {
    const { isLoading, experience, weekToShowId } = this.state
    const { currentWeekId } = experience.userExperience

    return (
      <Layout>
        <Loading isLoading={isLoading}>
          <div className="row align-items-center">
            <div className="col-sm-4">
              <img className="img-fluid p-3" src={experience.logoUrl}/>
            </div>
            <div className="col-sm-8">
              <h5>{experience.name}</h5>
              <div>{experience.description}</div>
            </div>
          </div>
          <hr />
          <VideosIndex videos={experience.videos} {...{currentWeekId, weekToShowId }} />
        </Loading>
      </Layout>
    )
  }
}

export default asSignedInOnlyPage(Show)

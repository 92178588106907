import validator from 'validator'

export const DEFAULT_USER = {
  password: '',
  phone: ''
};

export const validateForm = (data) => {
  const validations = {
    email: email => {
      const errors = []
      if (!validator.isEmail(email)) { errors.push('Email is invalid') }
      return errors
    },
    firstName: firstName => {
      const errors = []
      if (validator.isEmpty(firstName)) { errors.push('First name is required') }
      return errors
    },
    lastName: lastName => {
      const errors = []
      if (validator.isEmpty(lastName)) { errors.push('Last name is required') }
      return errors
    },
    phone: phone => {
      const errors = []
      if (phone && !validator.isLength(phone, { min: 10, max: 10 })) { errors.push('Invalid phone number') }
      return errors
    },
    password: phone => {
      const errors = []
      if (phone && !validator.isLength(phone, { min: 8 })) { errors.push('Password must be at least 8 characters long') }
      return errors
    },
  }

  const errors = {}
  Object.keys(validations).forEach(key => {
    const messages = validations[key](data[key])
    if (messages.length) { errors[key] = messages }
  })

  const isValid = !Object.keys(errors).length

  return { isValid, errors }
}

import _ from 'lodash'
import React from 'react'
import { Alert, Button, Form, FormText, Input } from 'reactstrap'

import * as axios from '../../helpers/axios'

import Destroy from './Destroy'
import FormGroup from '../forms/FormGroup'
import PhoneInput from '../forms/PhoneInput'

import { withAuth } from '../../contexts/Auth'
import { DEFAULT_USER, validateForm } from './helpers/form'

class UserForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      isLoading: false,
      ...DEFAULT_USER,
      ...this.props.user,
      departmentId: this.props.user.department.id
    }
  }

  handleChange = ({ target }) => {
    const value = target.type === 'checkbox' ? target.checked : target.value
    this.setState({ [target.name]: value })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.setState({ alert: null, isLoading: true })

    let user = _.omit(this.state, ['errors', 'alert', 'alertType'])

    const { errors, isValid } = validateForm(user)
    this.setState({ errors, isLoading: false })
    if (!isValid) { return }

    if (user.departmentId) {
      user.department = this.props.options.departments.find(option => {
        return option.id === user.departmentId
      })
    }

    user = user.password ? user : _.omit(user, 'password')
    const userId = this.props.auth.tokenPayload.id

    axios.request('post', `/api/v1/users/${userId}`, { user })
    .then(({ token }) => {
      this.props.auth.setToken(token)
      this.setState({
        alert: { text: 'Your settings were successfully updated', type: 'success' },
        isLoading: false
      })
    })
    .catch(() => {
      this.setState({
        alert: { text: 'There was a problem changing your settings', type: 'danger' },
        isLoading: false
      })
    })
  }

  render() {
    const { errors, alert, isLoading } = this.state
    const { calendarServices, departments } = this.props.options

    return (
      <div className="mb-5">
        { alert ? <Alert color={alert.type}>{alert.text}</Alert> : null }

        <Form onSubmit={this.handleSubmit}>
          <FormGroup
            label="Email"
            name="email"
            value={this.state.email}
            onChange={this.handleChange}
            invalid={errors.email && errors.email.length}
            errors={errors.email}
          />

          <FormGroup
            label="First Name"
            name="firstName"
            value={this.state.firstName}
            onChange={this.handleChange}
            invalid={errors.firstName && errors.firstName.length}
            errors={errors.firstName}
          />

          <FormGroup
            label="Last Name"
            name="lastName"
            value={this.state.lastName}
            onChange={this.handleChange}
            invalid={errors.lastName && errors.lastName.length}
            errors={errors.lastName}
          />

          <FormGroup
            label="Password"
            name="password"
            type="password"
            value={this.state.password}
            onChange={this.handleChange}
            invalid={errors.password && errors.password.length}
            errors={errors.password}
          />

          <FormGroup label="Department" name="department" errors={errors.phone}>
            <Input
              type="select"
              name="departmentId"
              value={this.state.departmentId || {}}
              invalid={errors.departmentId && errors.departmentId.length}
              onChange={this.handleChange}
            >
              <option value="">Pick your department</option>
              { departments.map(({ id, name }) => <option key={id} value={id}>{name}</option>) }
            </Input>
          </FormGroup>

          <FormGroup label="Phone" name="phone" errors={errors.phone}>
            <PhoneInput
              name="phone"
              value={this.state.phone || ''}
              invalid={errors.phone && errors.phone.length}
              onChange={this.handleChange}
            />
            <FormText>Optional: Add if you prefer SMS messages. Messages are sent weekday mornings between 8 and 11 AM.</FormText>
          </FormGroup>

          <FormGroup label="Calendar service" name="calendarService" errors={errors.phone}>
            <Input
              type="select"
              name="calendarService"
              value={this.state.calendarService || ''}
              invalid={errors.calendarService && errors.calendarService.length}
              onChange={this.handleChange}
            >
              <option value="">Pick the calendar service you use</option>
              { calendarServices.map(({ id, name }) => <option key={id} value={id}>{name}</option>) }
            </Input>
            <FormText>Optional: This will make it easier for you to re-schedule activities</FormText>
          </FormGroup>

          <Button color="primary" disabled={isLoading}>
            Save Settings
          </Button>
        </Form>

        <hr />

        <Destroy />
      </div>
    )
  }
}

export default withAuth(UserForm)

import _ from 'lodash'
import * as yup from 'yup'

export const videoSchema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string().required(),
  videoUrl: yup.string().url().test('', 'Enter at least one medium', function(value) {
    return this.parent.placeholderUrl || this.parent.vimeoId ? true : !!value
  }),
  placeholderUrl: yup.string().url().test('', 'Enter at least one medium', function(value) {
    return this.parent.videoUrl || this.parent.vimeoId ? true : !!value
  }),
  vimeoId: yup.string().test('', 'Enter at least one medium', function(value) {
    return this.parent.placeholderUrl || this.parent.videoUrl ? true : !!value
  }),
  dripActions: yup.object().shape({
    started: yup.string().required(),
    ended: yup.string().required(),
    played: yup.string().required(),
    thresholdPlayed: yup.string().required()
  })
})

export const videoDefaults = {
  weekId: '',
  title: '',
  description: '',
  videoUrl: '',
  placeholderUrl: '',
  vimeoId: '',
  dripActions: {
    started: '',
    ended: '',
    played: '',
    thresholdPlayed: ''
  },
  content: {
    links: []
  }
}

export const getInitialValues = (video) => {
  return video
  ? {
    ...videoDefaults,
    ..._.pickBy(video),
    dripActions: { ...videoDefaults.dripActions, ...video.dripActions }
  }
  : videoDefaults
}

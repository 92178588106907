import React from 'react'

import { Link } from '@reach/router'
import { Alert } from 'reactstrap'

import { asAdminSignedInOnlyPage } from '../../services/AdminSession'
import Http from '../../services/Http'
import Form from '../../components/experiences/Form'
import Page from '../../components/layout/Page'
import Breadcrumbs from '../../components/layout/Breadcrumbs'

class AdminExperienceEdit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      alert: null
    }
  }

  handlePatch = (values, { setSubmitting }) => {
    this.setState({ alert: null })

    const { experienceId } = this.props
    const experience = values

    return Http.patch(`/admin/v1/experiences/${experienceId}`, { experience })
    .then(({ experience }) => {
      this.setState({
        experience,
        alert: { type: 'success', text: 'Experience successfully updated' }
      })
      setSubmitting(false)
    })
  }

  render() {
    const { experience } = this.props
    const { alert } = this.state

    const breadcrumbs = [
      { url: '/admin/experiences', label: 'Experiences' }
    ]

    return (
      <Page>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <div className="d-flex justify-content-between align-items-center">
          <h6 className="mb-0">{experience.name}</h6>

          <Link className="btn btn-outline-secondary" to={`/admin/experiences/${experience.id}/videos`}>
            Edit videos
          </Link>
        </div>

        <hr />

        { alert
          ? <div>
              <Alert color={alert.type}>{alert.text}</Alert>
              <hr />
            </div> : null }

        <Form experience={experience} onPatch={this.handlePatch} />
      </Page>
    )
  }
}

export default asAdminSignedInOnlyPage(AdminExperienceEdit)

import React from 'react'

const InvitationItem = ({ department, firstName, lastName, email, status }) => (
  <div className="card">
    <div className="card-body d-flex row">
      <div className="col-9">
        <div className="text-muted">{email}</div>
      </div>

      <div className="col-3">
        <span className="w-100 badge badge-success">{status}</span>
      </div>

      <div className="col-12">
        <div>{firstName} {lastName}</div>
      </div>

      <div className="col-12">
        <div className="text-muted small">{department && department.name}</div>
      </div>
    </div>
  </div>
)

export default InvitationItem

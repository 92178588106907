import _ from 'lodash'
import React from 'react'
import { Formik, Form } from 'formik'
import { Row, Col, Button } from 'reactstrap'

import FormikFormGroup from '../forms/FormikFormGroup'
import FormSection from '../forms/FormSection'

import LinkFieldsArray from './links/FieldsArray'

import { videoSchema, getInitialValues } from './helpers'

const ExperienceForm = ({ video, onPatch }) => {
  const initialValues = getInitialValues(video)

  return (
    <Formik onSubmit={onPatch} initialValues={initialValues} validationSchema={videoSchema}>
      {({ values, isSubmitting, resetForm }) => {
        const handleCancel = () => { resetForm(initialValues) }

        return (
          <Form className="mb-0">

            <FormSection title="General information">
              <Row>
                <Col xs={2}>
                  <FormikFormGroup label="Week" name="weekId" />
                </Col>

                <Col xs={9} md={6}>
                  <FormikFormGroup label="Title" name="title" />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={10}>
                  <FormikFormGroup label="URL Slug" name="slug" />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={10}>
                  <FormikFormGroup type="textarea" rows={4} label="Description" name="description" />
                </Col>
              </Row>
            </FormSection>

            <hr />

            <FormSection title="Media">
              <Row>
                <Col xs={12} md={8}>
                  <FormikFormGroup label="Vimeo Id" name="vimeoId" />
                  <FormikFormGroup label="Video URL" name="videoUrl" />
                  <FormikFormGroup label="Placeholder image URL" name="placeholderUrl" />
                </Col>
              </Row>
            </FormSection>

            <hr />

            <FormSection title="Drip parameters">
              <Row>
                <Col xs={12} md={8}>
                  <FormikFormGroup label="Drip started action" name="dripActions.started" />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={8}>
                  <FormikFormGroup label="Drip ended action" name="dripActions.ended" />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={8}>
                  <FormikFormGroup label="Drip played action" name="dripActions.played" />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={8}>
                  <FormikFormGroup label="Drip threshold played action" name="dripActions.thresholdPlayed" />
                </Col>
              </Row>
            </FormSection>

            <hr />

            <FormSection title="Links">
              <LinkFieldsArray values={values} />
            </FormSection>

            <hr />

            <div className="d-flex justify-content-end">
              <Button type="button" disabled={isSubmitting} onClick={handleCancel} className="mr-2" outline color="secondary">Cancel</Button>
              <Button type="submit" disabled={isSubmitting} color="primary">{ video ? "Update video" : "Create video"}</Button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ExperienceForm

import React from 'react'

import './icons'

import { Router, Redirect } from '@reach/router'

import { provideAuth } from './contexts/Auth'
import { provideAdminSession } from './admin/services/AdminSession'

import SignIn from './pages/SignIn'
import Dashboard from './pages/Dashboard'
import Settings from './pages/Settings'

import InvitationShow from './pages/invitations/Show'
import PasswordNew from './pages/passwords/New'
import PasswordShow from './pages/passwords/Show'
import ExperienceIndex from './pages/experiences/Index'
import ExperienceShow from './pages/experiences/Show'
import VideoShow from './pages/videos/Show'

import AdminRoutes from './admin/Routes'

const App = () => (
  <Router>
    <Redirect noThrow from="/" to="experiences" />
    <SignIn path="signin" />
    <Dashboard path="dashboard" />
    <Settings path="settings" />

    <InvitationShow path="invitations/:invitationCode" />

    <PasswordNew path="passwords/new" />
    <PasswordShow path="passwords/:passwordToken" />

    <ExperienceIndex path="experiences" />
    <ExperienceShow path="experiences/:experienceId" />
    <VideoShow path="videos/:videoSlug" />

    <AdminRoutes path="admin/*" />
  </Router>
)

export default provideAdminSession(provideAuth(App))

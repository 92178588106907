import * as yup from 'yup'

export const invitationSchema = yup.object().shape({
  email: yup.string().email().required(),
  firstName: yup.string(),
  lastName: yup.string(),
  phone: yup.string().length(10).matches(/^[0-9]+$/, 'phone must be only numbers'),
  role: yup.string().matches(/(manager|user)/),
  department: yup.object().shape({
    id: yup.string().required('department is required'),
    name: yup.string().required('department is required')
  })
})

export const invitationDefaults = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  role: 'user',
  department: {
    id: '',
    name: ''
  }
}

import React from 'react'

import { Link } from '@reach/router'

const AdminExperienceItem = ({ experience }) => {
  return (
    <div>
      <hr />
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <span className="text-muted">{experience.id}</span> {experience.name}
        </div>
        <Link className="btn btn-outline-secondary" to={`/admin/experiences/${experience.id}/edit`}>Edit</Link>
      </div>
    </div>
  )
}

export default AdminExperienceItem

import React from 'react'
import _ from 'lodash'
import queryString from 'query-string'

import * as axios from '../helpers/axios'

import Layout from '../components/layout/Layout'
import Loading from '../components/layout/Loading'
import UserItem from '../components/users/Item'
import InvitationItem from '../components/invitations/Item'
import Chart from '../components/dashboard/Chart'
import ReportTable from '../components/dashboard/report/Table'
import ReportForm from '../components/dashboard/report/Form'

import { asManagerOnlyPage } from '../contexts/Auth'

class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      reports: [],
      reportData: [],
      reportColumnTitles: [],
      chartData: [],
      chartColumnTitles: [],
      users: [],
      invitations: [],
    }
  }

  componentDidMount() {
    this.getUsers()
  }

  getUsers = () => {
    const { accountId } = this.props.auth.tokenPayload;
    Promise.all([
      axios.request('get', `/api/v1/accounts/${accountId}/departments`)
      .then(({ departments }) => {
        this.setState({ departments })
      }),
      axios.request('get', `/api/v1/accounts/${accountId}/reports/videosCompletedPerWeek`)
      .then(({ data, columnTitles }) => {
        this.setState({ chartData: data, chartColumnTitles: columnTitles })
      }),
      axios.request('get', `/api/v1/accounts/${accountId}/reports`)
      .then(({ reports }) => {
        this.setState({ reports })
        return reports
      })
      .then((reports) => {
        const reportSlug = reports[0].slug
        return axios.request('get', `/api/v1/accounts/${accountId}/reports/${reportSlug}`)
      })
      .then(({ data, columnTitles }) => {
        this.setState({ reportData: data, reportColumnTitles: columnTitles })
      }),
      axios.request('get', `/api/v1/accounts/${accountId}/invitations`)
      .then(({ invitations }) => {
        this.setState({ invitations })
      }),
      axios.request('get', '/api/v1/users')
      .then(({ users }) => {
        this.setState({ users })
      })
    ])
    .then(() => {
      this.setState({ isLoading: false })
    })
    .catch(() => {
      this.setState({ isLoading: false })
    })
  }

  handleReportUpdate = ({ filters, setSubmitting }) => {
    const { accountId } = this.props.auth.tokenPayload
    const { reportId } = filters
    const search = queryString.stringify(_.pick(filters, 'days'))

    axios.request('get', `/api/v1/accounts/${accountId}/reports/${reportId}?${search}`)
    .then(({ data, columnTitles }) => {
      setSubmitting(false)
      this.setState({ reportData: data, reportColumnTitles: columnTitles })
    })
  }

  render() {
    const {
      isLoading,
      users,
      invitations,
      reports,
      reportData,
      reportColumnTitles,
      chartData,
      chartColumnTitles
    } = this.state

    const reportOptions =
      reports
      .filter((report) => !(report.slug === 'activityPerWeek' || report.slug == 'videosCompletedPerWeek'))
      .map((report) => ({ value: report.slug, label: report.name }))

    return (
      <Layout>
        <Loading isLoading={isLoading}>
          <h5>Account dashboard</h5>
          <hr />

          <div className="row">
            {reports.length
              ? <div className="col-12 row">
                  <div className="col-12">
                    <h6 className="text-muted">User activity</h6>
                    <Chart data={chartData} columnTitles={chartColumnTitles} />
                    <hr />
                  </div>

                  <div className="col-12 col-lg-8">
                    <h6 className="text-muted">Reports</h6>
                    <ReportForm onReportUpdate={this.handleReportUpdate} reportOptions={reportOptions} />
                  </div>

                  <div className="col-12">
                    <ReportTable data={reportData} columnTitles={reportColumnTitles} />
                    <hr />
                  </div>
                </div>: null}

            <div className="col-12 col-md-6 border-md-right pb-5">
              <h6 className="mb-4 text-muted">Users you manage</h6>
              {
                users.length
                ? users.map(user => <div key={user.id} className="mb-2"><UserItem {...user}/></div>)
                : <div className="alert alert-warning">Nothing to show here</div>
              }
            </div>

            <div className="col-12 col-md-6 pb-5">
              <h6 className="mb-4 text-muted">Outstanding invitations</h6>
              {
                invitations.length
                ? invitations.map(invitation => <div key={invitation.id} className="mb-2"><InvitationItem {...invitation}/></div>)
                : <div className="alert alert-warning">Nothing to show here</div>
              }
            </div>
          </div>
        </Loading>
      </Layout>
    )
  }
}

export default asManagerOnlyPage(Dashboard)

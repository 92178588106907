import React from 'react'
import axios from 'axios'

import { Redirect } from '@reach/router'

import Layout from '../../components/layout/Layout'
import Loading from '../../components/layout/Loading'

import { asSignedInOnlyPage } from '../../contexts/Auth'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      experiences: []
    }
  }

  componentDidMount() {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }

    axios.get(`/api/v1/experiences`, { headers })
    .then(({ data: { experiences }}) => {
      this.setState({ experiences, isLoading: false })
    })
  }

  render() {
    const { isLoading, experiences } = this.state

    return (
      <Layout>
        <Loading isLoading={isLoading}>
          <h5>My experiences</h5>
          <hr />
          {experiences.length === 1
            ? <Redirect noThrow to={`experiences/${experiences[0].id}`} /> : null}
          {!experiences.length
            ? <div className="alert alert-warning">You are not enrolled in any experiences</div>
            : null}
        </Loading>
      </Layout>
    )
  }
}

export default asSignedInOnlyPage(Index)

import React from 'react'
import * as axios from '../../helpers/axios'

import { navigate } from '@reach/router'

import { withAuth } from '../../contexts/Auth'

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

class CancelAccount extends React.Component {
  constructor(props) {
    super(props)
    this.state = { confirmModal: false }
  }

  openModal = () => {
    this.setState({ confirmModal: true })
  }

  closeModal = () => {
    this.setState({ confirmModal: false })
  }

  handleCancel = () => {
    axios.request('delete', `api/v1/users/${this.props.auth.tokenPayload.id}`)
    .then(() => {
      this.props.auth.setToken(null)
      navigate('/')
    })
  }

  render() {
    return (
      <div>
        <div className="card border-danger">
          <div className="card-body">
            <h6 className="text-danger">Danger zone</h6>
            <div className="d-flex align-items-center justify-content-between">
              <div className="text-muted">
                This will remove all your information from the app
              </div>
              <Button outline color="danger" onClick={this.openModal}>Cancel Account</Button>
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.confirmModal}>
          <ModalHeader> Confirm Cancel Account </ModalHeader>
          <ModalBody>
            Are you sure you want to cancel your Account?<br/><br/>
            This will cancel your current experience and stop future messages from it immediately.
            Any information or content you've created in The Orsini Way will also be removed.<br/><br/>

            Pushing the Cancel Account button below is not reversible.
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.handleCancel}>
              Cancel Account
            </Button>{' '}
            <Button color="success" onClick={this.closeModal}>
              Keep Account Active
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default withAuth(CancelAccount)

import React from 'react'
import { Col, FormGroup, FormFeedback, Label, Input } from 'reactstrap'

const CustomFormGroup = props => (
  <FormGroup row>
    {
      props.label
      ? <Label for={props.name} sm={3} md={3}>
          {props.label}
        </Label>
      : null
    }
    <Col sm={props.label ? 9 : 12} md={props.label ? 9 : 12}>
      {
        props.children
        ? props.children
        : <Input {...props}/>
      }
      {
        props.errors && props.errors.length
        ? props.errors.map(error => <FormFeedback key={error}>{error}</FormFeedback>)
        : null
      }
    </Col>
  </FormGroup>
)

export default CustomFormGroup

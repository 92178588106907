import React from 'react'
import _ from 'lodash'
import axios from 'axios'

import FormGroup from '../../components/forms/FormGroup'
import Layout from '../../components/layout/Layout'

import { asSignedOutOnlyPage, withAuth } from '../../contexts/Auth'
import { validateNewForm } from './helpers'

class New extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      errors: {},
      email: '',
      alert: null
    }
  }

  handleChange = ({ target }) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({ [target.name]: value });
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({ isLoading: true, alert: null })

    const user = _.pick(this.state, 'email')
    const { errors, isValid } = validateNewForm(user)

    if (!isValid) {
      this.setState({ errors, isLoading: false })
      return;
    }

    axios.post('/api/v1/passwords', { user }, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(() => {
      this.setState({ alert: { type: 'success', message: 'Check your email for instructions' }, isLoading: false })
    })
    .catch(() => {
      this.setState({ alert: { type: 'danger', message: "Couldn't find an account for this email" }, isLoading: false })
    })
  }

  render() {
    const { errors, alert } = this.state
    return (
      <Layout>
        <div className="card m-auto" style={{ maxWidth: '400px' }}>
            <div className="card-body">
              <h6 className="mb-3 text-muted">Reset password</h6>
              { alert ? <div className={`alert alert-${alert.type}`}>{alert.message}</div> : null }
              <form className="d-flex flex-column" onSubmit={this.handleSubmit}>
                <FormGroup
                  placeholder="Email"
                  name="email"
                  type="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  invalid={errors.email && !!errors.email.length}
                  errors={errors.email}
                />

                <button
                  className="btn btn-block btn-primary"
                  disabled={this.state.isLoading}
                  type="submit"
                >
                  Send recovery instructions
                </button>
              </form>
            </div>
        </div>
      </Layout>
    )
  }
}

export default withAuth(asSignedOutOnlyPage(New))

import _ from 'lodash'
import React from 'react'
import { Table } from 'reactstrap'

const DashboardReportTable = ({ data, columnTitles }) => {
  const columnKeys = _.keys(columnTitles)

  return (
    <Table responsive={true} borderless={true} striped={true}>
      <thead>
        <tr className="th-light">
          {columnKeys.map(columnKey => (
            <th key={columnKey}>{columnTitles[columnKey]}</th>))}
        </tr>
      </thead>

      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            {columnKeys.map(columnKey => (
              <td key={columnKey}>{row[columnKey] || '--'}</td>))}
          </tr>))}
      </tbody>
    </Table>
  )
}

export default DashboardReportTable

import React from 'react'
import { FieldArray } from 'formik'
import { Button } from 'reactstrap'

import LinkFields from './Fields'

const VideoLinksFieldsArray = ({ values }) => {
  return (
    <FieldArray name="content.links">
      {(arrayHelpers) => (
        <div>
          {values.content.links.map((_link, index) => (
            <LinkFields key={index} index={index} arrayHelpers={arrayHelpers} />
          ))}

          <div className="d-flex justify-content-end">
            <Button className="border-0" outline color="secondary" type="button" onClick={() => arrayHelpers.push({ label: '', url: '' })}>
              Add a link
            </Button>
          </div>
        </div>
      )}
    </FieldArray>
  )
}

export default VideoLinksFieldsArray

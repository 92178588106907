import React from 'react'
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Legend, Tooltip } from 'recharts'

const AccountActivityChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <Line type="monotone" dataKey="videosCompleted" name="Videos completed" stroke="#ff0000" />
        <XAxis dataKey="date" />
        <YAxis />
        <Legend align="right" verticalAlign="top" height={40} />
        <Tooltip />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default AccountActivityChart

import React from 'react'

import { withAdminSession } from '../../services/AdminSession'

const AdminSignOut = ({ adminSession, className }) => {
  return (
    <button
      type="button" className={`${className} btn btn-link`}
      onClick={() => { adminSession.setToken(null) }}
    >
      Sign Out (Admin)
    </button>
  )
}

export default withAdminSession(AdminSignOut)

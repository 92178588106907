export const formatPhone = phone => {
  if (!phone) {
    return ''
  }

  if (phone.length > 6) {
    phone = phone.substr(0, 6) + '-' + phone.substr(6, phone.length)
  }

  if (phone.length > 3) {
    phone = phone.substr(0, 3) + ') ' + phone.substr(3, phone.length)
  }

  if (phone.length > 0) {
    phone = '(' + phone.substr(0, phone.length)
  }

  return phone
}

import React from 'react'

import { Alert } from 'reactstrap'

import { asAdminSignedInOnlyPage } from '../../services/AdminSession'
import Http from '../../services/Http'

import AdminLayout from '../../components/layout/Layout'
import Page from '../../components/layout/Page'
import Loading from '../../components/layout/Loading'
import Item from '../../components/experiences/Item'

class AdminExperienceIndex extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      experiences: []
    }
  }

  componentDidMount() {
    Http.get('/admin/v1/experiences')
    .then(({ experiences }) => {
      this.setState({ experiences, isLoading: false })
    })
  }

  render() {
    const { isLoading, experiences } = this.state

    return (
      <AdminLayout>
        <Page>
          <Loading isLoading={isLoading}>
            <h6>Experiences</h6>

            {!experiences.length
              ? <Alert color="warning">There aren't any experiences</Alert>
              : null}

            {experiences.map(experience => <Item key={experience.id} experience={experience} />)}
          </Loading>
        </Page>
      </AdminLayout>
    )
  }
}

export default asAdminSignedInOnlyPage(AdminExperienceIndex)

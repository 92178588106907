import React from 'react';

import { withAuth } from '../../contexts/Auth'

const SignOut = ({ auth, className }) => {
  return (
    <button type="button" className={`${className} btn btn-link`} onClick={() => { auth.setToken(null) }}>
      Sign Out{ auth.currentUser ? ` (${auth.currentUser.firstName})` : ''}
    </button>
  )
}

export default withAuth(SignOut)

import _ from 'lodash'
import React from 'react'
import { FormGroup, FormFeedback, Label, Input } from 'reactstrap'

const CustomFormGroup = ({ field, form, label, type, options, ...props }) => (
  <FormGroup>
    {label ? <Label className="small text-muted">{label}</Label> : null}

    <Input
      type={ type || 'text'} {...field} {...props}
      invalid={_.get(form.errors, field.name) && _.get(form.touched, field.name)}
    >
      {type === 'select' && options
        ? options.map(({ value, label }) => (
          <option key={value} value={value}>{label}</option>))
        : null}
    </Input>

    <FormFeedback className="text-right">{_.get(form.errors, field.name)}</FormFeedback>
  </FormGroup>
)

export default CustomFormGroup

import React from 'react'
import { Link } from '@reach/router'

const Breadcrumbs = ({ breadcrumbs }) => (
  <nav>
    <ol className="breadcrumb bg-white p-0">
      {breadcrumbs.map(({ url, label }) => (
        <li key={label} className="breadcrumb-item">
          {url
            ? <Link to={url}>{label}</Link>
            : label}
        </li>
      ))}
    </ol>
  </nav>
)

export default Breadcrumbs

import React from 'react'

import { Card, CardBody } from 'reactstrap'

const Page = ({ children }) => (
  <div className="py-4">
    <Card>
      <CardBody>{children}</CardBody>
    </Card>
  </div>
)

export default Page

import axios from 'axios'

export const request = (method, url, data) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('token')}`
  }

  return (
    method === 'get' || method === 'delete'
    ? axios[method](url, { headers })
    : axios[method](url, data, { headers })
  )
  .then(({ data }) => data)
  .catch(({ response }) => Promise.reject(response.data || {}))
}

import _ from 'lodash'
import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Player from './Player'

const StatusBadge = ({ status }) => {
  const badgeClass = { ended: 'badge-success', started: 'badge-info' }[status]
  const badgeText = { ended: 'Completed', started: 'Started' }[status]
  return <span className={`badge ${badgeClass}`}>{badgeText}</span>
}

class Item extends React.Component {
  constructor(props) {
    super(props)

    this.cardRef = React.createRef()

    this.state = {
      status: this.props.userVideo.ended
      ? 'ended'
      : this.props.userVideo.started ? 'started' : 'notSeen',
      isOpen: this.props.isFocused
    }
  }

  componentDidMount() {
    if (this.props.isFocused) {
      this.cardRef.current.scrollIntoView(true)
    }
  }

  handleStatusChange = (status) => {
    this.setState({ status })
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const { status, isOpen } = this.state
    const { id, vimeoId, videoUrl, placeholderUrl, title, userVideo, isCurrent } = this.props

    return (
      <div ref={this.cardRef} className="card mr-auto mb-3">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div className="font-weight-bold mr-2">{title}</div>
              { isCurrent ? <span className="badge badge-danger mr-1">Current week</span> : null }
              { status !== 'notSeen' ? <StatusBadge status={status} /> : null }
            </div>

            {!this.props.hideCollapseButton
              ? <button className="btn btn-outline-secondary border-0" onClick={this.toggle}>
                  <FontAwesomeIcon icon={this.state.isOpen ? 'chevron-up' : 'chevron-down'} />
                </button>
              : null}
          </div>
        </div>

        {isOpen
          ? <div>
              {
                this.props.vimeoId || this.props.videoUrl
                ? <Player {...{ id, vimeoId, videoUrl, userVideo, onStatusChange: this.handleStatusChange }} />
                : <img src={placeholderUrl} className="card-img-top bg-dark w-100" />
              }
              <div className="card-body">
                { this.props.description ? <p className="mb-3">{this.props.description}</p> : null }
                {
                  this.props.content.links
                  ? <div className="d-flex flex-column">
                      { this.props.content.links.map(({ url, label }) => <a key={url} href={url}>{label}</a>) }
                    </div>
                  : null
                }
              </div>
            </div>
          : null}
      </div>
    )
  }
}

export default Item

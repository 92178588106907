import React from 'react'

import { Link } from '@reach/router'
import {
  Navbar,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem
} from 'reactstrap'

import logoUrl from '../../../assets/images/orsini-logo.png'

import { withAdminSession } from '../../services/AdminSession'

import SignOut from '../sessions/SignOut'

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const { token } = this.props.adminSession

    return (
      <div className="my-4">
        <Navbar light expand="md" className="px-0" className="flex-column align-items-start">
          <div className="d-flex align-items-center mb-4">
            <a href='https://theorsiniway.com'><img className="navbar-logo pr-2" src={logoUrl} /></a>
            <div className="pl-2 border-left h6 mb-0">Admin</div>
          </div>

          <NavbarToggler onClick={this.toggle} />

          <Collapse isOpen={this.state.isOpen} navbar>
            {token
              ? <Nav className="ml-auto flex-column" navbar>
                  <NavItem><Link className="nav-link" to="/admin/experiences">Experiences</Link></NavItem>
                  <NavItem><Link className="nav-link" to="/admin/accounts">Accounts</Link></NavItem>
                  <NavItem><SignOut className="nav-link" /></NavItem>
                </Nav>
              : null}
          </Collapse>
        </Navbar>
      </div>
    )
  }
}

export default withAdminSession(AdminNavbar)

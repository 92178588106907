import React from 'react'

import { Redirect } from '@reach/router'

const ADMIN_TOKEN_KEY = 'adminToken'

const AdminSessionContext = React.createContext(null)

export const provideAdminSession = Component => {
  class AdminSessionProvider extends React.Component {
    constructor(props) {
      super(props)

      const token = localStorage.getItem(ADMIN_TOKEN_KEY)

      this.state = {
        token,
        isSignedIn: !!token
      }
    }

    componentDidMount() {
      window.addEventListener('storage', this.handleTokenChange)
      this.updateCurrentUserData()
    }

    componentWillUnmount() {
      window.removeEventListener('storage', this.handleTokenChange)
    }

    handleTokenChange = ({ key, storageArea }) => {
      if (key === ADMIN_TOKEN_KEY && storageArea === localStorage) {
        this.updateCurrentUserData()
      }
    }

    updateCurrentUserData = () => {
      const token = localStorage.getItem(ADMIN_TOKEN_KEY)

      this.setState({
        token: token,
        isSignedIn: !!token
      })
    }

    setToken = (token) => {
      if (token) {
        localStorage.setItem(ADMIN_TOKEN_KEY, token)
      } else {
        localStorage.removeItem(ADMIN_TOKEN_KEY)
      }

      this.updateCurrentUserData()
    }

    render() {
      const value = {
        ...this.state,
        setToken: this.setToken
      }

      return (
        <AdminSessionContext.Provider value={value}>
          <Component {...this.props} />
        </AdminSessionContext.Provider>
      )
    }
  }

  return AdminSessionProvider
}

export const withAdminSession = Component => (
  props => (
    <AdminSessionContext.Consumer>
      {adminSession => (
        <Component {...props} adminSession={adminSession} />
      )}
    </AdminSessionContext.Consumer>
  )
)

export const asAdminSignedInOnlyPage = (Component) => {
  const AdminSignedInOnlyPage = (props) => (
    props.adminSession && props.adminSession.isSignedIn
    ? <Component {...props} />
    : <Redirect noThrow to="/admin/sessions/new" />
  )

  return withAdminSession(AdminSignedInOnlyPage)
}

export const asAdminSignedOutOnlyPage = (Component) => {
  const AdminSignedOutOnlyPage = (props) => (
    props.adminSession && props.adminSession.isSignedIn
    ? <Redirect noThrow to="/admin/accounts" />
    : <Component {...props} />
  )

  return withAdminSession(AdminSignedOutOnlyPage)
}
